import React, { useMemo } from 'react'
import { useText } from '@visx/text'
import 'twin.macro'

const getFormattedChildren = (hasSpaceForDesc, children, subtitle) =>
  hasSpaceForDesc ? children : `${children} ${subtitle}`

const getWidthSubtitle = (width, subtitle) => {
  const customWidth = width * 0.9 // Take up less width than label
  const min = 9 // Minimum number of letters which will result in reduced width
  if (subtitle.length <= min) {
    return customWidth * Math.min(subtitle.length / 10, 0.6)
  }
  return customWidth
}

const getWidth = (width, hasSpaceForDesc, children, subtitle) => {
  const text = getFormattedChildren(hasSpaceForDesc, children, subtitle)
  // Fix for small labels scaling to huge proportions to the allocated width
  if (text.length <= 2) {
    return width * 0.25
  }
  if (text.length <= 5) {
    return width * 0.6
  }
  return width
}

export default function ArcLabel({
  width,
  angle,
  x,
  y,
  scaleToFit,
  textAnchor,
  color = '#000',
  verticalAnchor,
  subtitle,
  hasSpaceForDesc,
  children,
  handleClick,
  ...domProps
}) {
  const getCustomWidth = useMemo(
    () => getWidth(width, hasSpaceForDesc, children, subtitle),
    [width, hasSpaceForDesc, children, subtitle]
  )

  const { wordsByLines, startDy, transform } = useText({
    width: getCustomWidth,
    angle,
    x,
    y,
    scaleToFit,
    verticalAnchor,
    textAnchor,
    children: getFormattedChildren(hasSpaceForDesc, children, subtitle),
  })

  const { transform: subtitleTransform } = useText({
    width: hasSpaceForDesc ? getWidthSubtitle(width, subtitle) : getCustomWidth,
    angle,
    x,
    y,
    scaleToFit,
    verticalAnchor: 'start',
    textAnchor: 'middle',
    children: `${subtitle}`,
  })

  return (
    <svg {...domProps} onClick={handleClick} tw="overflow-visible">
      <text
        fill={color}
        transform={transform}
        x={x}
        y={y}
        textAnchor={textAnchor}
      >
        {wordsByLines.map((line, index) => (
          // Center title with dy if no space for two lines
          <tspan key={index} x={x} dy={hasSpaceForDesc ? 0 : startDy}>
            {line.words.join(' ')}
          </tspan>
        ))}
      </text>
      {hasSpaceForDesc && (
        <text
          fill={color}
          transform={subtitleTransform}
          x={x}
          y={y}
          dy="1.3em"
          textAnchor={textAnchor}
        >
          <tspan x={x} tw="truncate">
            {subtitle}
          </tspan>
        </text>
      )}
    </svg>
  )
}
