import { useMeasure } from 'react-use'
import tw, { theme } from 'twin.macro'

import { useFilter } from '../connectors/filterContext'
import ChartConfigSection from './ChartConfigSection'
import Sunburst from './Sunburst'
import {
  HaulOptions,
  TimeOptions,
  LocationOptions,
} from '../components/CustomOptions'
import useGetRoot from '../connectors/aggregateDataContext/useGetRoot'
import { partition } from 'd3-hierarchy'
import useGetKeyFromDepth from '../connectors/dataSelection/useGetKeyFromDepth'
import usePerFteSpecificValues from '../connectors/dataSelection/usePerFteSpecificValues'

const filterDefinitions = [
  // TODO demographics: age, gender, employee type, length of employment
  // TODO travel: direct vs layover, airline, frequent flyers
  { name: 'Haul', key: 'haul', cat: 'travel', options: <HaulOptions /> },
  { name: 'Time', key: 'time', cat: 'travel', options: <TimeOptions /> },
  { name: 'Booking Class', key: 'cls', cat: 'travel' },
  {
    name: 'Destination',
    key: 'location',
    cat: 'travel',
    options: <LocationOptions />,
  },
  { name: 'Reason', key: 'flight_reason', cat: 'travel' },
  { name: 'Duration', key: 'duration', cat: 'travel' },
  { name: 'Age', key: 'age', cat: 'demographics' },
  { name: 'Gender', key: 'gender', cat: 'demographics' },
  { name: 'Role', key: 'role', cat: 'demographics' },
  { name: 'Manager', key: 'manager', cat: 'demographics' },
  { name: 'Org Code', key: 'org_code', cat: 'demographics' },
  // { name: 'Start Day', key: 'startDay', cat: 'travel' },
]

export default function ExploreSunburst() {
  const { getRoot } = useGetRoot(filterDefinitions)
  const getKeyFromDepth = useGetKeyFromDepth(filterDefinitions)
  const { filteredList } = useFilter()
  const { rootNodeValue, isOrgInvalid } = usePerFteSpecificValues(
    filterDefinitions
  )

  const [ref, { width, height }] = useMeasure()

  const getD3Root = () => partition()(getRoot(100))

  return (
    <div tw="flex-1 flex flex-col lg:items-center justify-center lg:flex-row">
      <div
        css={[
          `height: calc(100vh - ${theme`spacing.20`});`,
          tw`w-full flex flex-col mx-auto lg:order-last overflow-x-auto`,
        ]}
        ref={ref}
      >
        {width && (
          <Sunburst
            getRoot={getD3Root}
            parentWidth={width}
            parentHeight={height}
            filteredList={filteredList}
            getKeyFromDepth={getKeyFromDepth}
            rootNodeValue={rootNodeValue}
            isOrgInvalid={isOrgInvalid}
          />
        )}
      </div>
      <ChartConfigSection
        filterDefinitions={filterDefinitions}
        isOrgInvalid={isOrgInvalid}
        tw="mt-4 lg:mt-0"
      />
    </div>
  )
}
