import React from 'react'

import Layout from '../../components/Layout'
import ExploreSunburst from '../../components/ExploreSunburst'

export default function SunburstPage() {
  return (
    <Layout>
      <ExploreSunburst />
    </Layout>
  )
}
