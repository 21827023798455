import React, {
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
  useReducer,
} from 'react'

import { Group } from '@visx/group'
import { arc } from '@visx/shape'
import { scaleLinear, scaleSqrt, scaleOrdinal } from '@visx/scale'
import { interpolate as d3interpolate } from 'd3-interpolate'
import { useSpring } from 'react-spring'
import {
  /*schemeTableau10*/ schemePurples as schemeSet,
} from 'd3-scale-chromatic'
import clampCircle from './clampCircle'
import contrast from 'contrast'
import tw, { theme } from 'twin.macro'

import { getSubtitle, getRoundedString } from '../../util'
import useSvgTooltip from '../../connectors/useSvgTooltip'
import TooltipContent from '../TooltipContent'
import Arc from './Arc'
import { getPrecision } from '../../util/charts'

const color = scaleOrdinal({ range: schemeSet[9].slice(2) })

const defaultMargin = { top: 0, left: 0, right: 0, bottom: 0 }

// Get path from list of all ancestors
const getPath = (node) => {
  try {
    const nodeToRootPath = node.ancestors().map((item) => item.data.name)
    const rootToNodePath = [...nodeToRootPath].reverse()
    return rootToNodePath
  } catch (err) {
    // Return empty array when node's option is removed from Levels
    return []
  }
}

const PathBreadcrumbs = ({ path, handleClick, root }) => {
  const rootDescendants = root.descendants()

  const handlePathClick = (pathName) => {
    if (path.length < 2) {
      return
    }

    // If pathName is undefined, the back button was clicked
    const nodeToFind = pathName ?? path[path.length - 2]
    const nodeIndex = rootDescendants.findIndex(
      (node) => node.data.name === nodeToFind
    )

    handleClick(rootDescendants[nodeIndex], nodeIndex)
  }

  return (
    <>
      {path.length > 1 && (
        <svg
          x="15"
          y="10"
          stroke="white"
          strokeOpacity="0"
          strokeWidth="10"
          width="16"
          height="16"
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
          tw="fill-current text-grey-2 cursor-pointer"
          onClick={() => handlePathClick()}
        >
          <path d="M12.8332 6.1666H3.5249L7.59157 2.09993C7.91657 1.77493 7.91657 1.2416 7.59157 0.916602C7.26657 0.591602 6.74157 0.591602 6.41657 0.916602L0.924902 6.40827C0.599902 6.73327 0.599902 7.25827 0.924902 7.58327L6.41657 13.0749C6.74157 13.3999 7.26657 13.3999 7.59157 13.0749C7.91657 12.7499 7.91657 12.2249 7.59157 11.8999L3.5249 7.83327H12.8332C13.2916 7.83327 13.6666 7.45827 13.6666 6.99993C13.6666 6.5416 13.2916 6.1666 12.8332 6.1666Z" />
        </svg>
      )}

      <text x="25" y="24">
        {path.map((pathName, i) => {
          const isCentralArc = path.length - 1 === i
          return (
            <tspan
              key={`path-${i}`}
              css={[
                tw`fill-current`,
                isCentralArc
                  ? tw`text-grey-1 pointer-events-none`
                  : tw`text-grey-2 cursor-pointer hover:underline`,
              ]}
              dx={'1em'}
              onClick={() => handlePathClick(pathName)}
            >
              {pathName}
              {!isCentralArc && <tspan dx="1em">/</tspan>}
            </tspan>
          )
        })}
      </text>
    </>
  )
}

const getIsNodeParentOfCentral = (node, root, centralArc) => {
  const parent = root.descendants()[centralArc.index]?.parent
  return parent && parent.data.name === node.data.name
}

const getIsNodeInRoot = (nodeName, nodeIndex, root) => {
  // Check if nodeName and nodeIndex are the same
  const isNodeInRoot = !!root.find(
    (node, i) => node.data.name === nodeName && i === nodeIndex
  )
  return isNodeInRoot
}

const ACTIONS = {
  CONTAINER_RESIZE: 'container-resize',
  HANDLE_CLICK: 'handle-click',
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.CONTAINER_RESIZE:
      return { ...state, yRangeHi: action.payload.maxWidth }
    case ACTIONS.HANDLE_CLICK:
      // Takes yRangeHi from state
      return { ...state, ...action.payload }
    default:
      return state
  }
}

/*
 * the data comes in getRoot, not root, because storybook doesn't tolerate
 * props that contain cycles.
 */
export default function Sunburst({
  getRoot,
  parentWidth: width,
  parentHeight: height,
  margin = defaultMargin,
  filteredList,
  getKeyFromDepth,
  rootNodeValue,
  isOrgInvalid,
}) {
  if (typeof getRoot !== 'function') {
    throw new Error('Need to supply a getRoot function')
  }
  const root = useMemo(getRoot, [getRoot])
  const lastTwoRoots = useRef([root])
  const rootChanged = useRef(false)

  const padding = 60
  const maxWidth = Math.min(width - padding, height - padding) / 2

  const initialState = {
    xDomain: [0, 1],
    yDomain: [0, 1],
    yRangeLo: 0,
    yRangeHi: maxWidth,
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const { xDomain, yDomain, yRangeLo, yRangeHi } = state
  const prevXDomain = useRef(xDomain)

  const [currentSelectedArc, setCurrentSelectedArc] = useState({
    index: 0,
    name: root.data.name,
    depth: 0,
  })

  const path = useMemo(
    () => getPath(root.descendants()[currentSelectedArc.index]),
    [root, currentSelectedArc]
  )

  const xScale = useRef(
    scaleLinear({ domain: xDomain, range: [0, 2 * Math.PI] })
  )
  const yScale = useRef(
    scaleSqrt({ domain: yDomain, range: [yRangeLo, yRangeHi] })
  )

  // Create t-parametrized functions from current domain/range (t=0)
  // to "next" domain/range (t=1)
  // Dropped xd - animating change in individual arc angles, not the whole domain in xScale
  const yd = d3interpolate(yScale.current.domain(), yDomain)
  const yr = d3interpolate(yScale.current.range(), [yRangeLo, yRangeHi])

  useEffect(() => {
    dispatch({
      type: ACTIONS.CONTAINER_RESIZE,
      payload: { maxWidth },
    })
  }, [maxWidth])

  // t = 0 => current arc; t = 1 => next arc.
  const interpolatedArc = useCallback(
    (node, index, t, invalid) => {
      const isDescendantOfCentralArc = !!node
        .ancestors()
        .find((ancestor) => ancestor.data.name === currentSelectedArc.name)

      const isAncestorOfCentralArc = !!node
        .descendants()
        .find((descendant) => descendant.data.name === currentSelectedArc.name)

      // Return "empty" arcs for nodes which don't have centralArc as ancestor or as descendant
      // or if node is "below" (at a lower depth) central arc
      if (
        !isAncestorOfCentralArc &&
        (!isDescendantOfCentralArc || node.depth <= currentSelectedArc.depth)
      ) {
        return arc({
          startAngle: 0,
          endAngle: 0,
          innerRadius: 0,
          outerRadius: 0,
        })
      }

      // Adjust scale to current progress.
      yScale.current.domain(yd(t)).range(yr(t))

      let startAngle = clampCircle(xScale.current(node.x0))
      let endAngle = clampCircle(xScale.current(node.x1))
      let innerRadius = Math.max(0, yScale.current(node.y0))
      let outerRadius = Math.max(0, yScale.current(node.y1))

      // Only animate node angles if previous root had the same node in the current root
      // Get current arc's previous properties, for prevNode's x0 and x1
      const prevRoot = lastTwoRoots.current[0]
      const prevNode = prevRoot?.find(
        (n, i) => n.data.name === node.data.name && i === index
      )

      // We need to use the previous xDomain's scale, current xDomain won't give correct values
      // Because prevNode's angles might not be within the scale of new domain
      const prevNodeXScale = xScale.current.copy().domain(prevXDomain.current)

      // Animate change in arc angle for arcs higher than centralArc
      if (prevNode && node.depth > currentSelectedArc.depth) {
        startAngle = d3interpolate(
          clampCircle(prevNodeXScale(prevNode.x0)),
          clampCircle(xScale.current(node.x0))
        )(t)
        endAngle = d3interpolate(
          clampCircle(prevNodeXScale(prevNode.x1)),
          clampCircle(xScale.current(node.x1))
        )(t)
      }

      return arc({
        startAngle,
        endAngle,
        innerRadius,
        outerRadius,
      })
    },
    [yd, yr, currentSelectedArc.name, currentSelectedArc.depth]
  )

  const [{ t }] = useSpring(
    () => ({
      from: { t: 0 },
      to: { t: 1 },
      reset: true,
      config: {
        clamp: true, // Prevents overshooting
        tension: 120,
        friction: 20,
      },
    }),
    [xDomain, yDomain, yRangeLo, yRangeHi]
  )

  const handleClick = useCallback((node, i) => {
    // Set the next domain/range to the extents of the clicked node.
    const xDomain = [node.x0, node.x1]
    const yDomain = [node.y0, 1]
    const yRangeLo = node.y0 ? 20 : 0

    // Update prevXDomain and xScale for new central arc
    prevXDomain.current = xScale.current.domain()
    xScale.current.domain(xDomain)

    // Re-render
    setCurrentSelectedArc({
      index: i,
      name: node.data.name,
      depth: node.depth,
    })
    dispatch({
      type: ACTIONS.HANDLE_CLICK,
      payload: { xDomain, yDomain, yRangeLo },
    })
  }, [])

  useEffect(() => {
    rootChanged.current = true

    // Track recent two roots (prev and curr because can't detect before root is changed with `useMemo`)
    // Used to animate the difference between prev and current root's arc angles/lengths in interpolatedArc
    lastTwoRoots.current.push(root)
    if (lastTwoRoots.current.length > 2) {
      lastTwoRoots.current = lastTwoRoots.current.slice(
        lastTwoRoots.current.length - 2
      )
    }
  }, [root])

  const updateChart = useCallback(() => {
    // Only update chart when root changes i.e. ignore other deps
    if (rootChanged.current) {
      rootChanged.current = false
      const { index, name } = currentSelectedArc
      const isCurrentArcInRoot = getIsNodeInRoot(name, index, root)

      if (isCurrentArcInRoot) {
        // Trigger correct arc animation when root changes
        handleClick(root.descendants()[index], index)
      } else {
        // Reset sunburst
        handleClick(root, 0)
      }
    }
  }, [root, currentSelectedArc, handleClick])

  useEffect(() => {
    updateChart()
  }, [updateChart])

  // Look for the categories to hide in all ancestors
  const getHideValue = useCallback(
    (node, filteredList) => {
      const shouldHide = node.ancestors().find((d) => {
        // Count depth starting from the children of root
        let nodeKey = node.depth ? getKeyFromDepth(d.depth - 1) : null

        return filteredList.find(
          (item) => item.name === d.data.name && item.key === nodeKey
        )
      })

      return !!shouldHide
    },
    [getKeyFromDepth]
  )

  const {
    containerRef,
    handlePointer,
    TooltipInPortal,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    tooltipData,
    tooltipStyles,
  } = useSvgTooltip()

  return width < 10 || root == null ? null : (
    <svg width={width} height={height} ref={containerRef}>
      <rect width={width} height={height} fill="none" />
      <Group top={margin.top} left={margin.left}>
        {path.length && (
          <PathBreadcrumbs path={path} handleClick={handleClick} root={root} />
        )}
        <Group top={height / 2} left={width / 2}>
          {root.descendants().map((node, i) => {
            const hide = getHideValue(node, filteredList)

            // Keep the root node's color constant, otherwise it's distracting if we change indicators.
            let bgColor =
              node.data.color ??
              color(node === root ? 'indicator' : node.data.name)
            let fgColor = contrast(bgColor) === 'light' ? '#000' : '#fff'

            // Don't show tooltip if node is parent of central arc
            const isNodeParentOfCentral = getIsNodeParentOfCentral(
              node,
              root,
              currentSelectedArc
            )

            const isCentralArc = i === currentSelectedArc.index
            const value =
              rootNodeValue && node === root ? rootNodeValue : node.value

            // TODO: Refactor out into a function
            let invalid = false
            if (
              isOrgInvalid &&
              getKeyFromDepth(node.depth - 1) === 'org_code'
            ) {
              invalid = true
              bgColor = theme`colors.grey.4`
              fgColor = bgColor
            }

            return hide ? null : (
              <Arc
                label={node.data.name}
                value={getRoundedString(value, getPrecision(value))}
                unit={root.data.unit}
                key={i}
                arcFill={bgColor}
                textColor={fgColor}
                handleClick={() => handleClick(node, i)}
                handlePointer={
                  isNodeParentOfCentral ? () => null : handlePointer
                }
                hideTooltip={hideTooltip}
                interpolatedArc={(t) => interpolatedArc(node, i, t)}
                t={t}
                isCentralArc={isCentralArc}
                invalid={invalid}
              />
            )
          })}
        </Group>
      </Group>

      {tooltipOpen && (
        <>
          <TooltipInPortal
            left={tooltipLeft}
            top={tooltipTop}
            style={tooltipStyles}
          >
            <TooltipContent
              indicator={path.join(' / ')}
              label={tooltipData.label}
              subLabel={
                tooltipData.value
                  ? getSubtitle(tooltipData.value, tooltipData.unit)
                  : null
              }
            />
          </TooltipInPortal>
        </>
      )}
    </svg>
  )
}
