import React, { useCallback, useMemo, useEffect } from 'react'
import memoizeOne from 'memoize-one'
import { Group } from '@visx/group'
import { animated, to } from 'react-spring'
import ArcLabel from './ArcLabel'
import tw from 'twin.macro'

import { getSubtitle } from '../../util'

const AnimatedArcText = animated(ArcLabel)

const FULL_CIRCLE = 2 * Math.PI

function radToDeg(angle) {
  return (180 * angle) / Math.PI
}

function largeAngleFactor(endAngle, startAngle) {
  return endAngle - startAngle < (1 / 3) * FULL_CIRCLE ? 0 : 1
}

function getMeasurements(arc) {
  const [centroidX, centroidY] = arc.centroid()
  const endAngle = arc.endAngle()()
  const startAngle = arc.startAngle()()
  const outerRadius = arc.outerRadius()()
  const innerRadius = arc.innerRadius()()

  const chordLength = outerRadius - innerRadius

  const rotAngleDeg =
    // The bigger the angle, the more horizontal i.e. towards 0 degrees
    // (but only starting at large angles)
    (1 - largeAngleFactor(endAngle, startAngle)) *
    ((radToDeg((endAngle + startAngle) / 2) % 180) - 90)

  const spanAngle = endAngle - startAngle

  return {
    path: arc(),
    centroidX,
    centroidY,
    chordLength,
    rotAngleDeg,
    spanAngle,
    innerRadius,
    outerRadius,
  }
}

export default function Arc({
  label,
  value,
  unit,
  interpolatedArc,
  arcFill,
  textColor,
  handleClick,
  t,
  isCentralArc,
  handlePointer,
  hideTooltip,
  invalid,
}) {
  // Measurements we want to animate.
  /* eslint-disable react-hooks/exhaustive-deps */
  const dimensions = useCallback(
    memoizeOne((t) => {
      return getMeasurements(interpolatedArc(t))
    }),
    [interpolatedArc]
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  // Non-animated measurements.
  const { spanAngle } = useMemo(() => {
    return getMeasurements(interpolatedArc(1))
  }, [interpolatedArc])

  const hasSpaceForTitle = spanAngle >= 0.15
  const hasSpaceForDesc = spanAngle >= 0.3

  useEffect(() => {
    isCentralArc && hideTooltip()
  }, [isCentralArc, hideTooltip])

  const handleTooltip = useCallback(
    (e) => {
      if (invalid) {
        return handlePointer(e, {
          label:
            'Inactive: in per-FTE view, “Org Code” must be the innermost ring',
        })
      }
      return isCentralArc || handlePointer(e, { label, value, unit })
    },
    [isCentralArc, handlePointer, label, value, unit, invalid]
  )

  return (
    <Group data-test-id={label}>
      <animated.path
        // could add will-change: transform;
        css={[
          tw`transform-gpu`,
          isCentralArc ||
            invalid ||
            tw`hover:(cursor-pointer filter brightness-90)`,
        ]}
        d={to([t], (t) => dimensions(t).path)}
        fill={arcFill}
        fillRule="evenodd"
        onClick={handleClick}
        onPointerEnter={handleTooltip}
        onPointerLeave={hideTooltip}
      />
      {!invalid && hasSpaceForTitle && (
        <AnimatedArcText
          width={to([t], (t) => dimensions(t).chordLength * 0.8)}
          angle={to([t], (t) => dimensions(t).rotAngleDeg)}
          x={to([t], (t) => dimensions(t).centroidX)}
          y={to([t], (t) => dimensions(t).centroidY)}
          scaleToFit
          color={textColor}
          textAnchor="middle"
          verticalAnchor="middle"
          subtitle={getSubtitle(value, unit)}
          hasSpaceForDesc={hasSpaceForDesc}
          onPointerEnter={handleTooltip}
          handleClick={handleClick}
          tw="pointer-events-none"
        >
          {label}
        </AnimatedArcText>
      )}
    </Group>
  )
}
