import { useMemo } from 'react'

import useData from '../../data/useData'
import { useActiveFilterIndices } from '../activeFilterIndices'
import { useDate } from '../dateContext'
import {
  getFlightTrips,
  useIndicatorTripMode,
} from '../indicatorTripModeContext'
import useIndicators from '../indicatorTripModeContext/useIndicators'
import { usePerFte } from '../perFteContext'
import { getFilteredData } from './useDataFilters'

export default function usePerFteSpecificValues(filterDefinitions) {
  const { flightLegs, airports } = useData()
  const { tripMode } = useIndicatorTripMode()
  const { perFte } = usePerFte()
  const { date } = useDate()
  const { activeFilterIndices } = useActiveFilterIndices()
  const { selectedIndicator } = useIndicators(perFte)

  const isOrgInvalid = useMemo(() => {
    const orgIndex = filterDefinitions.findIndex((d) => d.key === 'org_code')

    const isInvalid =
      perFte &&
      activeFilterIndices.includes(orgIndex) &&
      activeFilterIndices.indexOf(orgIndex) > 0
    return isInvalid
  }, [activeFilterIndices, filterDefinitions, perFte])

  const filteredData = useMemo(() => {
    const flights = tripMode ? getFlightTrips(airports, flightLegs) : flightLegs
    return getFilteredData(flights, date, perFte)
  }, [tripMode, airports, flightLegs, date, perFte])

  const isOrgBadgeActive = useMemo(() => {
    const orgIndex = filterDefinitions.findIndex((d) => d.key === 'org_code')
    return activeFilterIndices.includes(orgIndex)
  }, [filterDefinitions, activeFilterIndices])

  const rootNodeValue = useMemo(() => {
    if (perFte && isOrgBadgeActive) {
      const value = selectedIndicator.reducer(filteredData, isOrgInvalid)
      return value
    }

    return null
  }, [filteredData, selectedIndicator, isOrgInvalid, perFte, isOrgBadgeActive])

  return { rootNodeValue, isOrgInvalid }
}
